<template>
  <main><h1>404 - SNAPSHEET NOT FOUND</h1></main>
</template>

<script>
export default {
  name: "NotFound404",
};
</script>

<style lang="scss" scoped>
main {
  box-shadow: 0 0 6px 2px #0000001c;
  padding: 2rem;

  h1 {
    font-family: "SlateStd";
    text-align: center;
  }
}
</style>